<style>
[dir] .vs-input--input.normal {
  padding: 0.7rem !important;
}

[dir] .vs-con-input .vs-inputx {
  padding: 0.7rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.vs-input--input.normal {
  font-size: 1rem !important;
}
.vs-con-input .vs-inputx {
  font-size: 1rem;
}
.vs-input--input.normal {
  padding: 0.4rem;
}
.vs-input--input {
  color: inherit;
  position: relative;
  padding: 0.4rem;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
}
</style>
<template>
  <input
    class="vs-inputx vs-input--input normal hasValue"
    type="text"
    :value="formattedValue"
    @input="onInput"
    @blur="onBlur"
    @keydown="checkDigit"
  />
</template>

<script>
import _ from "lodash";

export default {
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    formattedValue() {
      const value = this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return value === "NaN" ? "" : value;
    },
  },
  methods: {
    checkDigit(event) {
      if (event.key.length === 1 && isNaN(Number(event.key))) {
        event.preventDefault();
      }
    },
    onInput(event) {
      // Remove all non-numeric characters except for the decimal point
      const rawValue = event.target.value.replace(/[^0-9.]/g, "");
      // Remove any extra decimal points
      const numericValue = parseFloat(rawValue);
      if (!isNaN(numericValue)) {
        event.target.value = rawValue;

        this.$emit("input", numericValue);
      } else {
        this.$emit("input", "");
      }
    },
    onBlur(event) {
      const rawValue = event.target.value.replace(/,/g, "");
      const numericValue = parseFloat(rawValue);
      if (!isNaN(numericValue)) {
        event.target.value = numericValue.toLocaleString();
      }
    },
  },
};
</script>
